import {utils} from '../../../components/helperComponents/Utils'

export function privaciesBy(statusCodes) {
  return (privacy) => statusCodes.includes(privacy.statusCode)
}

export function getPrivacyDetails(privacyCategory) {
  const privacies = privacyCategory.privacies[0].privacyPreferences
  const {
    consentNames,
    statusCodes,
    uiImage,
    uiName,
    type,
    homePageTab,
    learnMoreCategories,
    groupedConsentNames,
    triggeredUserId,
  } = privacyCategory

  return {
    consentNames,
    statusCodes,
    uiImage,
    uiName,
    type,
    homePageTab,
    privacies,
    learnMoreCategories,
    groupedConsentNames,
    triggeredUserId,
  }
}

export function forAuthorizedVins(authorizedVins) {
  return (device) => {
    return Boolean(authorizedVins.find((vin) => vin.vin === device.deviceValue))
  }
}

export function byDeviceTypeVin() {
  return (device) => device.deviceType === 'VIN'
}

function getVehicleDataMap(privacies) {
  const vehicleDataMap = new Map()
  privacies.forEach((privacy) => {
    const devices = privacy.devices
    devices.forEach((device) => {
      vehicleDataMap.set(
        device.deviceValue,
        `${device.deviceValue}#${privacy.statusCode}#${privacy.timeStamp}#${privacy.triggerUserId}`,
      )
    })
  })
  return vehicleDataMap
}

function getPreferredDealerVehicleDataMap(privacies) {
  const vehicleDataMap = new Map()
  privacies.forEach((privacy) => {
    const devices = privacy.devices
    devices.forEach((device) => {
      vehicleDataMap.set(
        device.deviceValue,
        `${device.deviceValue}#${privacy.statusCode}#${privacy.timeStamp}#${privacy.preferredDealerId}`,
      )
    })
  })
  return vehicleDataMap
}

function filterDevicesByDeviceTypeAndAunthorizedVins(privacy, authorizedVins) {
  return privacy.devices.filter(byDeviceTypeVin()).filter(forAuthorizedVins(authorizedVins))
}

function addDefaultDataToVehicleDataMap(vehicleDataMap, vin, data) {
  if (vehicleDataMap.has(vin)) {
    vehicleDataMap.get(vin).push(data)
  } else {
    vehicleDataMap.set(vin, [data])
  }
}

export function processPersonalizationPrivacies(
  privacyData,
  privacyCategory,
  authorizedVins,
  vehicleDataMap,
) {
  const {consentNames, statusCodes, uiName, type, homePageTab, privacies, learnMoreCategories} =
    getPrivacyDetails(privacyData.privacyCategories[privacyCategory])

  const authorizedPsnVins = authorizedVins.filter(
    (input) => !utils.errors.includes(input.engineType) && input.engineType.toUpperCase() === 'BEV',
  )

  const machePrivacies = privacies.filter(privaciesBy(statusCodes)).map((privacy) => {
    const devices = filterDevicesByDeviceTypeAndAunthorizedVins(privacy, authorizedPsnVins)
    privacy.devices = devices
    return privacy
  })

  const dataMap = getVehicleDataMap(machePrivacies)

  for (const data of authorizedPsnVins) {
    if (dataMap.has(data.vin)) {
      const [vin, statusCode, timeStamp, triggeredUserId] = dataMap.get(data.vin).split('#')

      if (vehicleDataMap.has(data.vin)) {
        vehicleDataMap.get(data.vin).push({
          vin,
          statusCode,
          timeStamp,
          triggerUserId: getTriggerUserId(triggeredUserId),
          consentNames,
          type,
          uiName,
          homePageTab,
          learnMoreCategories,
        })
      } else {
        vehicleDataMap.set(data.vin, [
          {
            vin,
            statusCode,
            timeStamp,
            triggerUserId: getTriggerUserId(triggeredUserId),
            consentNames,
            type,
            uiName,
            homePageTab,
            learnMoreCategories,
          },
        ])
      }
    } else {
      addDefaultDataToVehicleDataMap(vehicleDataMap, data.vin, {
        vin: data.vin,
        statusCode: 'N',
        timeStamp: null,
        triggerUserId: null,
        consentNames,
        type,
        uiName,
        homePageTab,
        learnMoreCategories,
      })
    }
  }
}

function getTriggerUserId(triggeredUserId) {
  return triggeredUserId !== null && triggeredUserId !== undefined ? triggeredUserId : null
}

export function processCarbPrivacies(privacyData, privacyCategory, authorizedVins, vehicleDataMap) {
  const {consentNames, statusCodes, uiName, type, homePageTab, privacies, learnMoreCategories} =
    getPrivacyDetails(privacyData.privacyCategories[privacyCategory])
  const evAndPhevFuelTypes = ['I', 'E']

  const authorizedCarbVins = authorizedVins.filter((input) => {
    const fuelType = input.fuelType ? input.fuelType.toUpperCase() : ''

    return evAndPhevFuelTypes.includes(fuelType)
  })
  const carbPrivacies = privacies.filter(privaciesBy(statusCodes)).map((privacy) => {
    const devices = filterDevicesByDeviceTypeAndAunthorizedVins(privacy, authorizedCarbVins)
    privacy.devices = devices
    return privacy
  })

  const dataMap = getVehicleDataMap(carbPrivacies)

  for (const data of authorizedCarbVins) {
    if (dataMap.has(data.vin)) {
      const [vin, statusCode, timeStamp, triggeredUserId] = dataMap.get(data.vin).split('#')

      if (vehicleDataMap.has(data.vin)) {
        vehicleDataMap.get(data.vin).push({
          vin,
          statusCode,
          timeStamp,
          triggerUserId: getTriggerUserId(triggeredUserId),
          consentNames,
          type,
          uiName,
          homePageTab,
          learnMoreCategories,
        })
      } else {
        vehicleDataMap.set(data.vin, [
          {
            vin,
            statusCode,
            timeStamp,
            triggerUserId: getTriggerUserId(triggeredUserId),
            consentNames,
            type,
            uiName,
            homePageTab,
            learnMoreCategories,
          },
        ])
      }
    } else {
      addDefaultDataToVehicleDataMap(vehicleDataMap, data.vin, {
        vin: data.vin,
        statusCode: 'N',
        timeStamp: null,
        triggerUserId: null,
        consentNames,
        type,
        uiName,
        homePageTab,
        learnMoreCategories,
      })
    }
  }
}

export function processTpePrivacies(privacyData, privacyCategory, authorizedVins, vehicleDataMap) {
  const {consentNames, statusCodes, uiName, type, homePageTab, privacies, learnMoreCategories} =
    getPrivacyDetails(privacyData.privacyCategories[privacyCategory])

  const tpePrivacies = privacies.filter(privaciesBy(statusCodes)).map((privacy) => {
    const devices = filterDevicesByDeviceTypeAndAunthorizedVins(privacy, authorizedVins)
    privacy.devices = devices
    return privacy
  })

  const dataMap = getVehicleDataMap(tpePrivacies)

  for (const data of authorizedVins) {
    if (dataMap.has(data.vin)) {
      const [vin, statusCode, timeStamp, triggeredUserId] = dataMap.get(data.vin).split('#')

      if (vehicleDataMap.has(data.vin)) {
        vehicleDataMap.get(data.vin).push({
          vin,
          statusCode,
          timeStamp,
          triggerUserId: getTriggerUserId(triggeredUserId),
          consentNames,
          type,
          uiName,
          homePageTab,
          learnMoreCategories,
        })
      } else {
        vehicleDataMap.set(data.vin, [
          {
            vin,
            statusCode,
            timeStamp,
            triggerUserId: getTriggerUserId(triggeredUserId),
            consentNames,
            type,
            uiName,
            homePageTab,
            learnMoreCategories,
          },
        ])
      }
    } else {
      addDefaultDataToVehicleDataMap(vehicleDataMap, data.vin, {
        vin: data.vin,
        statusCode: 'N',
        timeStamp: null,
        triggerUserId: null,
        consentNames,
        type,
        uiName,
        homePageTab,
        learnMoreCategories,
      })
    }
  }
}

export function processAmazonAlexaPrivacies(privacyData, privacyCategory, authorizedVins, vehicleDataMap) {
  const {consentNames, statusCodes, uiImage, uiName, type, homePageTab, privacies, learnMoreCategories} =
    getPrivacyDetails(privacyData.privacyCategories[privacyCategory])

  const amazonAlexaPrivacies = privacies.filter(privaciesBy(statusCodes)).map((privacy) => {
    const devices = filterDevicesByDeviceTypeAndAunthorizedVins(privacy, authorizedVins)
    privacy.devices = devices
    return privacy
  })

  const dataMap = getVehicleDataMap(amazonAlexaPrivacies)

  for (const data of authorizedVins) {
    if (dataMap.has(data.vin)) {
      const [vin, statusCode, timeStamp, triggeredUserId] = dataMap.get(data.vin).split('#')

      if (vehicleDataMap.has(data.vin)) {
        vehicleDataMap.get(data.vin).push({
          vin,
          statusCode,
          timeStamp,
          triggerUserId: getTriggerUserId(triggeredUserId),
          consentNames,
          type,
          uiImage,
          uiName,
          homePageTab,
          learnMoreCategories,
        })
      } else {
        vehicleDataMap.set(data.vin, [
          {
            vin,
            statusCode,
            timeStamp,
            triggerUserId: getTriggerUserId(triggeredUserId),
            consentNames,
            type,
            uiImage,
            uiName,
            homePageTab,
            learnMoreCategories,
          },
        ])
      }
    }
  }
}

export function processSucPrivacies(
  privacyData,
  privacyCategory,
  authorizedVins,
  garageVins,
  vehicleDataMap,
) {
  const {consentNames, statusCodes, uiName, type, homePageTab, privacies, learnMoreCategories} =
    getPrivacyDetails(privacyData.privacyCategories[privacyCategory])

  const sucPrivacies = privacies.filter(privaciesBy(statusCodes)).map((privacy) => {
    const devices = filterDevicesByDeviceTypeAndAunthorizedVins(privacy, authorizedVins)
    privacy.devices = devices
    return privacy
  })

  const dataMap = getVehicleDataMap(sucPrivacies)

  for (const data of authorizedVins) {
    if (dataMap.has(data.vin)) {
      const [vin, statusCode, timeStamp, triggeredUserId] = dataMap.get(data.vin).split('#')

      if (vehicleDataMap.has(data.vin)) {
        vehicleDataMap.get(data.vin).push({
          vin,
          statusCode,
          timeStamp,
          triggerUserId: getTriggerUserId(triggeredUserId),
          consentNames,
          type,
          uiName,
          homePageTab,
          learnMoreCategories,
          isVinAuthorized: Boolean(authorizedVins.find((vehicle) => vehicle.vin === vin)),
        })
      } else {
        vehicleDataMap.set(data.vin, [
          {
            vin,
            statusCode,
            timeStamp,
            triggerUserId: getTriggerUserId(triggeredUserId),
            consentNames,
            type,
            uiName,
            homePageTab,
            learnMoreCategories,
            isVinAuthorized: Boolean(authorizedVins.find((vehicle) => vehicle.vin === vin)),
          },
        ])
      }
    } else if (vehicleDataMap.has(data.vin)) {
      vehicleDataMap.get(data.vin).push({
        vin: data.vin,
        statusCode: 'N',
        timeStamp: null,
        triggerUserId: null,
        consentNames,
        type,
        uiName,
        homePageTab,
        learnMoreCategories,
        isVinAuthorized: Boolean(authorizedVins.find((vehicle) => vehicle.vin === data.vin)),
      })
    } else {
      vehicleDataMap.set(data.vin, [
        {
          vin: data.vin,
          statusCode: 'N',
          timeStamp: null,
          triggerUserId: null,
          consentNames,
          type,
          uiName,
          homePageTab,
          learnMoreCategories,
          isVinAuthorized: Boolean(authorizedVins.find((vehicle) => vehicle.vin === data.vin)),
        },
      ])
    }
  }
}

export function processMobileAppCookiePrivacies(privacyData, privacyCategory, authorizedVins, macDataMap) {
  const {
    consentNames,
    statusCodes,
    uiImage,
    uiName,
    type,
    homePageTab,
    privacies,
    learnMoreCategories,
    groupedConsentNames,
  } = getPrivacyDetails(privacyData.privacyCategories[privacyCategory])

  const groupPrivacyData = []

  consentNames.forEach((consentName) => {
    const privacy = privacies
      .filter(privaciesBy(statusCodes))
      .find((privacyItem) => privacyItem.consentName === consentName)

    function getGroupNameForPrivacy() {
      return Object.keys(groupedConsentNames).find((groupName) =>
        groupedConsentNames[groupName].includes(consentName),
      )
    }

    if (privacy) {
      groupPrivacyData.push({
        consentName,
        timestamp: privacy.timeStamp,
        statusCode: privacy.statusCode,
        groupName: getGroupNameForPrivacy(consentName),
      })
    } else {
      groupPrivacyData.push({
        consentName,
        timestamp: null,
        statusCode: 'N',
        groupName: getGroupNameForPrivacy(consentName),
      })
    }
  })
  macDataMap.set('MAC', {
    consentNames,
    uiImage,
    type,
    uiName,
    homePageTab,
    groupPrivacyData,
    learnMoreCategories,
    groupedConsentNames,
  })
}

export function processVehicleHealthToDealerprivacies(
  privacyData,
  privacyCategory,
  authorizedVins,
  vehicleDataMap,
) {
  const {consentNames, statusCodes, uiName, type, homePageTab, privacies, learnMoreCategories} =
    getPrivacyDetails(privacyData.privacyCategories[privacyCategory])

  const dealerPrivacies = privacies.filter(privaciesBy(statusCodes)).map((privacy) => {
    const devices = filterDevicesByDeviceTypeAndAunthorizedVins(privacy, authorizedVins)
    privacy.devices = devices
    return privacy
  })

  const dataMap = getVehicleDataMap(dealerPrivacies)

  for (const data of authorizedVins) {
    if (dataMap.has(data.vin)) {
      const [vin, statusCode, timeStamp, triggeredUserId] = dataMap.get(data.vin).split('#')

      if (vehicleDataMap.has(data.vin)) {
        vehicleDataMap.get(data.vin).push({
          vin,
          statusCode,
          timeStamp,
          triggerUserId: getTriggerUserId(triggeredUserId),
          consentNames,
          type,
          uiName,
          homePageTab,
          learnMoreCategories,
        })
      } else {
        vehicleDataMap.set(data.vin, [
          {
            vin,
            statusCode,
            timeStamp,
            triggerUserId: getTriggerUserId(triggeredUserId),
            consentNames,
            type,
            uiName,
            homePageTab,
            learnMoreCategories,
          },
        ])
      }
    } else {
      addDefaultDataToVehicleDataMap(vehicleDataMap, data.vin, {
        vin: data.vin,
        statusCode: 'N',
        timeStamp: null,
        triggerUserId: null,
        consentNames,
        type,
        uiName,
        homePageTab,
        learnMoreCategories,
      })
    }
  }
}

// TODO - check for no vin no dealer popup condition, add preferredId filter if preferred dealerId is not availNor null.
export function processPreferredDealerprivacies(
  privacyData,
  privacyCategory,
  authorizedVins,
  vehicleDataMap,
) {
  const {consentNames, statusCodes, uiName, type, homePageTab, privacies, learnMoreCategories} =
    getPrivacyDetails(privacyData.privacyCategories[privacyCategory])

  const dealerPrivacies = privacies.filter(privaciesBy(statusCodes)).map((privacy) => {
    const devices = filterDevicesByDeviceTypeAndAunthorizedVins(privacy, authorizedVins)
    privacy.devices = devices
    return privacy
  })

  const dataMap = getPreferredDealerVehicleDataMap(dealerPrivacies)

  for (const data of authorizedVins) {
    if (dataMap.has(data.vin)) {
      const [vin, statusCode, timeStamp, preferredDealerId] = dataMap.get(data.vin).split('#')

      if (vehicleDataMap.has(data.vin) && !utils.errors.includes(preferredDealerId)) {
        vehicleDataMap.get(data.vin).push({
          vin,
          statusCode,
          timeStamp,
          preferredDealerId,
          consentNames,
          type,
          uiName,
          homePageTab,
          learnMoreCategories,
        })
      } else if (!utils.errors.includes(preferredDealerId)) {
        vehicleDataMap.set(data.vin, [
          {
            vin,
            statusCode,
            timeStamp,
            preferredDealerId,
            consentNames,
            type,
            uiName,
            homePageTab,
            learnMoreCategories,
          },
        ])
      }
    }
  }
}
