export const errorFlag = ({state, errorStatus, message, feature}) => ({
  type: `[ERROR] BANNER`,
  payload: {
    state,
    errorStatus,
    message,
    feature,
  },
})

export const dismissErrorBanner = () => ({
  type: `[ERROR] BANNER DISMISS`,
})

export const setPreferredDealerErrorMsg = (errorMsg) => ({
  type: `[ERROR] PREFERRED DEALER`,
  payload: {
    errorMsg,
  },
})
