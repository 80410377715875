import {errorFlag} from '../../actions/ErrorActions'
import {APP_CONSTANT, FEATURE_UI_NAME} from '../../../components/helperComponents/Constants'
import {
  setManageDataWithThirdParties,
  setUnlinkThirdPartyAccounts,
  setManageUserDataWithThirdParties,
} from '../../actions/TabActions'
import {utils} from '../../../components/helperComponents/Utils'
import i18n from 'i18next'
import {BANNER_FEATURE, BANNER_TYPE} from '../../../components/error/ErrorBanner'
import {getBrandedTranslation} from '../../../components/helperComponents/contentUtils'

function processUserConsentData(store, userConsentAppsData) {
  const userConsents = []
  userConsentAppsData.forEach((app) => {
    app.consents.forEach((consent) => {
      const obj = utils.deepMergeObjects(
        {...app, timeStamp: consent.consentTimestamp},
        utils.filterObject(consent, [
          'llId',
          'appCountryCode',
          'consentStatus',
          'consentTimestamp',
          'consentContext',
        ]),
      )
      userConsents.push(obj)
    })
  })

  userConsents.forEach((app) => {
    delete app.consents
    delete app.consentTimestamp
  })

  store.dispatch(setManageUserDataWithThirdParties(userConsents))
}

function filterGoogleConsentByLLID(app, consent) {
  if (app.appNameUI === FEATURE_UI_NAME.googleMapsInFordPass) {
    const NA_GOOGLE_MAPS_LLID = '974a87bc-5c5e-49a2-bb66-8f1e1eb6ed1b'
    const EU_GOOGLE_MAPS_LLID = '9ff17127-019a-44fe-8496-9a03a502b350'
    const TUR_GOOGLE_MAPS_LLID = 'a3891ba4-e101-4b21-a2fc-467eda507988'
    const PHL_THL_GOOGLE_MAPS_LLID = '6d3e49d7-5834-44c6-8382-0fb04a318cf7'
    const AUS_NZL_GOOGLE_MAPS_LLID = 'bd28d358-212c-47c0-b8ad-6c2159dd4ecf'
    const SA_GOOGLE_MAPS_LLID = 'b56671df-8026-4591-b660-ae1c37f635d2'
    const ZAF_GOOGLE_MAPS_LLID = '6cf01f2f-5cd9-4d27-adf7-f9a816df4688'
    return consent.llId === NA_GOOGLE_MAPS_LLID || consent.llId === EU_GOOGLE_MAPS_LLID ||
      consent.llId === TUR_GOOGLE_MAPS_LLID || consent.llId === PHL_THL_GOOGLE_MAPS_LLID ||
      consent.llId === AUS_NZL_GOOGLE_MAPS_LLID || consent.llId === SA_GOOGLE_MAPS_LLID ||
      consent.llId === ZAF_GOOGLE_MAPS_LLID
  }
  return true
}

let IMG_ZAF_COUNTRIES = ['AUS', 'NZL', 'PHL', 'THA', 'ZAF', 'LSO', 'BWA', 'NAM', 'SWZ']
export const consentNormalizerMiddleware = (store) => (next) => (action) => {
  function extractConsents(app) {
    const authVinList = store.getState().vins.authorizedVins
    const appData = action.payload.data
    if (IMG_ZAF_COUNTRIES.includes(appData.countryCode)) {
      return app.consents
        .filter((consent) => filterGoogleConsentByLLID(app, consent))
        .filter((consent) => !utils.errors.includes(consent.consentTimestamp))
        .filter((consent) => authVinList.find((authVin) => consent.vin === authVin.vin || consent.vin === null))
    } else {
      return app.consents
        .filter((consent) => filterGoogleConsentByLLID(app, consent))
        .filter((consent) => !utils.errors.includes(consent.consentTimestamp))
        .filter((consent) => !utils.errors.includes(consent.llNameC))
        .filter((consent) => authVinList.find((authVin) => consent.vin === authVin.vin || consent.vin === null))
    }
  }

  if (action.payload && action.payload.normalizer) {
    switch (action.type) {
      case '[CONSENT_DATA] NORMALIZE':
        try {
          const applicationData = action.payload.data.applications.applications
          const consentAppsData = Object.keys(applicationData)
            .filter((appKey) => applicationData[appKey].isLaunched === 'true')
            .map((appKey) => applicationData[appKey])
            .map((app) => {
              const data = {}
              data.uiName = app.appNameUI
              data.uiImage = app.appImage
              data.sourceId = app.sourceId
              data.isLaunched = app.isLaunched
              data.consents = extractConsents(app)
              data.brand = app.brand
              data.type = app.type
              data.applicationId = app.applicationId
              data.learnMoreCategories = app.learnMoreCategories
              data.llNamec = app.llNameCodes
              return data
            })
            .filter((app) => app.consents.length > 0)

          const vehicleConsentAppsData = consentAppsData.filter((app) => app.consents[0]?.vin)
          const userConsentAppsData = consentAppsData.filter((app) => app.type.includes('ASC'))

          // Handle User Based Consent Data
          processUserConsentData(store, userConsentAppsData)

          // Handle Vehicle Based Consent Data
          const appsByVin = vehicleConsentAppsData
            .filter((element) => element.consents.length > 0)
            .map((app) => utils.processAppsByVin(app))

          const groupedAppsByVinData = appsByVin.reduce((acc, obj) => {
            for (const vinKey in obj) {
              if (Object.hasOwn(obj, vinKey)) {
                const existingEntry = acc.find((item) => item[vinKey])
                if (existingEntry) {
                  existingEntry[vinKey].push(obj[vinKey])
                  existingEntry[vinKey].sort((a, b) => a.uiName.localeCompare(b.uiName))
                } else {
                  acc.push({[vinKey]: [obj[vinKey]]})
                }
              }
            }
            return acc
          }, [])

          const unlinkThirdPartyDataMap = new Map()
          const thirdPartyDataMap = new Map()
          groupedAppsByVinData.forEach((entry) => {
            for (const vinKey in entry) {
              if (Object.hasOwn(entry, vinKey)) {
                const items = entry[vinKey]
                const tpItems = []
                const unlinkItems = []
                items.forEach((item) => {
                  if (item.uiName === FEATURE_UI_NAME.gridServices || item.uiName === FEATURE_UI_NAME.txu) {
                    item.timeStamp = item.consentTimeStamp
                    tpItems.push(item)
                  } else if (item.type[0] !== 'NTP') {
                    unlinkItems.push(item)
                  }
                })
                thirdPartyDataMap.set(vinKey, tpItems)
                unlinkThirdPartyDataMap.set(vinKey, unlinkItems)
              }
            }
          })
          store.dispatch(setManageDataWithThirdParties(thirdPartyDataMap))
          store.dispatch(setUnlinkThirdPartyAccounts(unlinkThirdPartyDataMap))
        } catch (error) {
          catchConsentError(store, action, error)
        }
        break
      default:
        next(action)
    }
  } else {
    next(action)
  }
}

function catchConsentError(store, action, error) {
  console.log(
    `Error while normalizing consents data for source ${action.type} and error details: ${error.toString()}`,
  )

  store.dispatch(
    errorFlag({
      state: BANNER_TYPE.ERROR,
      errorStatus: true,
      message: APP_CONSTANT.CONFIG.IS_OWNER
        ? i18n.t('lbl_generic_error_web_part1')
        : getBrandedTranslation('lbl_generic_error'),
      feature: BANNER_FEATURE.GENERIC,
    }),
  )
}
